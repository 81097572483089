import React from 'react'

import DefaultLayout from '@hoc/Layout/Default'

const Error = () => {
    return (
        <DefaultLayout>
            <p>404 PAGE GOES HERE</p>
        </DefaultLayout>
    )
}

export default Error
